<template>
  <div class="home">
      <div class="mall_logo">
        <img :src="mall_logo" alt="">
      </div>
    <div class="home_main">
      <img src="https://www.airustore.com/static/img/shoppingProcess.e336d04.gif" alt="">
      <img src="https://www.airustore.com/static/img/hot_title_bg.4775289.gif" alt="">
    </div>
    
    <router-view/>    
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'index',
  components: {
  },
  data() {
    return {
      mall_logo: "",
    }
  },
  created() {
    this.getList()
  },
  methods :{
    getList() {
      this.$axios
        .post(this.$request_ea.interface.setting.home.getList)
        .then(res => {
          if(res.data.code == 0) {
            let data = res.data.data
            this.mall_logo = data.domain.mall_logo
          }
        })
        .catch(
          function (err) {
            console.log(err);
            this.done = false;
          }.bind(this)
        );
    },
    
  },
}
</script>

<style lang="less" scoped>
  .home {
    .mall_logo {
      width: 100%;
      text-align: center;
      img {
        height: 50px;
      }
    }

    .home_main {
      max-width: 750px;
      margin: 0 auto;
    }
  }
</style>
