import Vue from 'vue'
import VueRouter from 'vue-router'
import index from '../views/index.vue'

Vue.use(VueRouter)

const routes = [

  {
    path: '/',
    name: 'index',
    component: () => import(/* webpackChunkName: "about" */ '../views/index')
  },
  {
    path: '/p/:id',
    name: 'advertise',
    component: () => import(/* webpackChunkName: "about" */ '../views/advertise')
  },
  {
    path: '/detail/:id',
    name: 'detail',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/detail')
  },
  {
    path: '/checkout/:id',
    name: 'checkout',
    component: () => import(/* webpackChunkName: "about" */ '../views/checkout')
  },
  {
    path: '/paySuccess/:number',
    name: 'paySuccess',
    component: () => import(/* webpackChunkName: "about" */ '../views/paySuccess'),

  },
  {
    path:'/other1',
    name:'other1',
    component: () => import('../components/other/other1.vue')
  },
  {
    path: '/other2',
    name: 'other2',
    component: () => import('../components/other/other2.vue')
  },
  {
    path: '/other3',
    name: 'other3',
    component: () => import('../components/other/other3.vue')
  },
  {
    path: '/other4',
    name: 'other4',
    component: () => import('../components/other/other4.vue')
  },
  {
    path: '/other5',
    name: 'other5',
    component: () => import('../components/other/other5.vue')
  },
  {
    path: '/other6',
    name: 'other6',
    component: () => import('../components/other/other6.vue')
  },
  {
    path: '/other7',
    name: 'other7',
    component: () => import('../components/other/other7.vue')
  },
  {
    path: '/other8',
    name: 'other8',
    component: () => import('../components/other/other8.vue')
  },
  {
    path: '/other9',
    name: 'other9',
    component: () => import('../components/other/other9.vue')
  },
  // {
  //   path: '/other_contact_us',
  //   name: 'other_contact_us',
  //   component: () => import(/* webpackChunkName: "about" */ '../components/other/contact_us')
  // },
  // {
  //   path: '/other_privacy_policy',
  //   name: 'other_privacy_policy',
  //   component: () => import(/* webpackChunkName: "about" */ '../components/other/privacy_policy')
  // },
  // {
  //   path: '/other_terms',
  //   name: 'other_terms',
  //   component: () => import(/* webpackChunkName: "about" */ '../components/other/terms')
  // },
  // {
  //   path: '/other_shipping',
  //   name: 'other_shipping',
  //   component: () => import(/* webpackChunkName: "about" */ '../components/other/shipping_delivery')
  // },
  // {
  //   path: '/other_refund',
  //   name: 'other_refund',
  //   component: () => import(/* webpackChunkName: "about" */ '../components/other/refund_policy')
  // },

  {
    path: '*',
    name: '404',
    component: () => import(/* webpackChunkName: "about" */ '../views/404page')
  },

]




const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    // return 期望滚动到哪个的位置
    return { x: 0, y: 0 }
    }
})

export default router
