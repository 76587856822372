//接口管理文件


const request = {
    interface: {
        portal: {
                member: {
                    register: '/kr/member/register', //注册用户
                    login: '/kr/member/login', //用户登陆
                    updatePassword: '/kr/member/updatePassword', //修改密码
                }
            },
            setting: {
                home: {
                    getList: '/kr/home/getList', //获取主页信息
                    getRecommend: '/kr/home/getRecommend', //下拉加载热门，推荐信息
                    getPicPosition: '/kr/home/getPicPosition', //获取显示在顶部的活动图片
                    getTimedProduct: '/kr/home/getTimedProduct', //获取限时商品列表
                },
                cate: {
                    getList: '/kr/cate/getList', //获取分类信息
                }
            },
            product: {
                CateProduct: {
                    index: '/kr/CateProduct/index', //分类列表
                    searchCate: '/kr/CateProduct/searchCate', //搜索分类
                    getProductByCate: '/kr/CateProduct/getProductByCate', //下拉加载商品
                },
                product: {
                    index: '/kr/product/index', //商品详情
                    quote: '/kr/product/quote', //下单页商品详情
                    addComment: '/kr/product/addComment', //添加评论
                    getAllComment: '/kr/product/getAllComment', //获取评论列表
                    getList: '/kr/product/getList', //获取瀏覽記錄列表
                    getRecommendList: '/kr/product/getRecommendList' //下单成功页推荐商品
                }
            },
            cart: {
                cart: {
                    insert: '/kr/cart/insert', //添加到购物车
                    cartInfo: '/kr/cart/cartInfo', //购物车列表
                    updateCartinfo: '/kr/cart/updateCartinfo', //更新购物车信息
                },
                buy: {
                    buyInfo: '/kr/buy/buyInfo', //购买信息
                    doPay: '/kr/buy/doPay', //生成订单
                    getOrderInfo: '/kr/buy/getOrderInfo', //订单编号获取订单订单信息
                    returnUrl: '/kr/buy/returnUrl', //在线支付成功返回链接
                },
                promote: {
                    getAllPromote: '/kr/promote/getAllPromote', //获取所有优惠策略
                }
            },
            member: {
                member: {
                    createMemberAddress: '/kr/member/createMemberAddress', //新建收货地址
                    getMemberAddress: '/kr/member/getMemberAddress', //收货地址列表
                    setDefaultAddress: '/kr/member/setDefaultAddress', //设为默认收货地址
                    getAddressInfo: '/kr/member/getAddressInfo', //获取收货详情
                    udateMemberAddress: '/kr/member/udateMemberAddress', //更新收货地址
                    deleteMemberAddress: '/kr/member/deleteMemberAddress', //删除收货地址
                    upload: '/kr/member/upload', //上传头像
                    update: '/kr/member/update', //更新用户信息
                },
                purchased: {
                    getPurchasedList: '/kr/purchased/getPurchasedList', //删除收货地址
                }
            },
            tool: {
                upload: {
                    upload: '/kr/upload/upload', //上传图片
                }
            },
            order: {
                order: {
                    query: '/kr/order/query' //お番号或いはオーダナンバーをご入力ください訂單信息
                }
            },
            pixel: {
                product: {
                    send: '/kr/pixel/send' //fb像素追踪
                }
            },
            domain: {
                domain: {
                    getPayDetail: '/kr/domain/detail'
                }
            }
    }
}


export default request;