import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    //加载状态
    loadingState:false,
  },
  getters: {
  },
  mutations: {
    //加载状态
    switch_loading(state, bool){
      state.loadingState = bool
  },
  },
  actions: {
  },
  modules: {
  }
})
