import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import ElementUI from 'element-ui'
import request_ea from './utils/request.js'
import qs from 'qs'

import 'element-ui/lib/theme-chalk/index.css';
import { Loading } from 'element-ui'


Vue.prototype.$request_ea = request_ea;
Vue.prototype.$axios = axios;
Vue.prototype.$qs = qs;

Vue.use(ElementUI);



// 添加请求拦截器
axios.interceptors.request.use(function (config) {

  // 在发送请求之前做些什么

  Loading.service();
  return config;
}, function (error) {
  // 对请求错误做些什么
  store.commit('switch_loading', false)
  return Promise.reject(error);
});

// 添加响应拦截器
axios.interceptors.response.use(function (response) {
  // 对响应数据做点什么

  let loadingInstance = Loading.service();
  loadingInstance.close()
  return response;
}, function (error) {
  // 对响应错误做点什么
  store.commit('switch_loading', false)
  return Promise.reject(error);
});



var httpArr = window.location.host.split('.')
var domainName = (httpArr[0] == 'www' ? httpArr[1] : httpArr[0]);
//测试接口
// axios.defaults.baseURL = 'http://api.kr820003.com';
// axios.defaults.baseURL = 'https://api.ucaremall.com';
// axios.defaults.baseURL = 'https://api.baron-kr1.com.com';

//正式接口
axios.defaults.baseURL = 'https://api.' + domainName +'.com';
Vue.prototype.homePage = 'https://www.' + domainName + '.com';

//下单接口
Vue.prototype.$placeOrderUrl = 'https://payment.moreonce.com/kr/buy/dopay';
// Vue.prototype.$placeOrderUrl = 'http://api.new-payment.com/kr/buy/dopay';

Vue.config.productionTip = false

router.beforeEach((to, from, next) => {
  // 锚点判断不加loading
  if(!to.hash.includes("steps")) {
    Loading.service();
  }
  next()

})
router.afterEach((to, from) => {
  // if (to.name == "other_contact_us" || to.name == "other_privacy_policy" || to.name == "other_terms" || to.name == "other_shipping" || to.name == "other_refund") {
  if(to.name.includes("other") || to.name == 404) {
    let loadingInstance = Loading.service();
    loadingInstance.close()
  }
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
